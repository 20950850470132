import React, { useState, useRef, useEffect } from 'react';
import Button from './Button';
import { DirectionsInfo } from './arrays/DirectionsInfo';



function Directions({ onOpenModal }) {
    return (
        <div className="main__section">
            {DirectionsInfo.map((data, key) => {
                return (
                    <div key={key} className="main__section__directions" id='directions'>
                        <div className="main__section__directions__content" id={data.id}>
                            <InfoSection title={data.title} info={data.info} link={data.link} id={data.id}  onOpenModal={onOpenModal} />
                            <img loading="lazy" src={data.img} alt={data.title} className="main__section__directions__content__img directionsImg" />
                        </div>
                    </div>
                )
            })}
        </div>
    );
}

function InfoSection({ title, info, link, id, onOpenModal }) {
    const [isExpanded, setIsExpanded] = useState(false);
    const textBoxRef = useRef(null);
    const [isOverflowing, setIsOverflowing] = useState(false);

    useEffect(() => {
        // Проверяем, превышает ли высота контейнера 100px
        if (textBoxRef.current && textBoxRef.current.scrollHeight > 100) {
            setIsOverflowing(true);
        }
    }, []);

    const toggleExpand = () => setIsExpanded(!isExpanded);

    // const handleMoreInfoClick = () => {
    //     // Открытие ссылки в новой вкладке
    //     window.open(link, '_blank');
    // };

    return (
        <div className="main__section__directions__content__info">
            <p className="main__section__directions__content__info__title">{title}</p>
            <div
                className={`main__section__directions__content__info__textBox ${isExpanded ? 'expanded' : 'collapsed'}`}
                ref={textBoxRef}
            >
                {Object.values(info).map((text, index) => (
                    <p
                        className="main__section__directions__content__info__textBox__text"
                        key={index}
                        dangerouslySetInnerHTML={{ __html: text }}
                    />
                ))}
            </div>
            {isOverflowing && (
                <button className="toggle-button" onClick={toggleExpand}>
                    {isExpanded ? 'Меньше ▲' : ' Больше ▼'}
                </button>
            )}
            <div className="main__section__directions__content__info__buttons special-button-container">
                {/* один компонент */}
                {id === 'school' ? (
                    // Для блока "Начальная школа" отображаем одну кнопку "оставить заявку"
                    // <Button text="оставить заявку" isLong={true} modalTarget="#form"/>
                    <Button text="оставить заявку" isLong={true} onOpenModal={onOpenModal} />
                ) : id === 'oge' ? (
                    // Для блока "Подготовка к ОГЭ" отображаем только кнопку "подробнее"
                        <Button text="подробнее" link={link} />
                ) : (
                    // Для всех остальных блоков отображаем две кнопки
                    <>
                        {/* <Button text="бесплатное пробное занятие" isLong={true}  modalTarget="#form"/> */}
                        <Button text="бесплатное пробное занятие" isLong={true} onOpenModal={onOpenModal} />
                        <Button text="подробнее" link={link} />
                    </>
                )}
            </div>
        </div>
    );
}

export default Directions;





























