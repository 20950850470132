import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';

import Button from './Button';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import 'swiper/css/effect-cards';

import feature1 from '../img/platform/feature1__img.svg';
import feature2 from '../img/platform/feature2__img.svg';
import feature3 from '../img/platform/feature3__img.svg';
import feature4 from '../img/platform/feature4__img.svg';
import feature5 from '../img/platform/feature5__img.svg';
import feature6 from '../img/platform/feature6__img.svg';
import feature7 from '../img/platform/feature7__img.svg';
import feature8 from '../img/platform/feature8__img.svg';

import stars from '../img/platform/stars.svg';

function Platform({ onOpenModal }) {
    const slides = [
        { image: feature1, text: '<span class="text__highlight">200 +</span><br/>интерактивных заданий' },
        { image: feature2, text: '<span class="text__highlight">50 +</span><br/>игр и квестов' },
        { image: feature3, text: 'Проверка <span class="text__highlight">индивидуальных домашних</span> заданий на платформе' },
        { image: feature4, text: 'Ежемесячный <span class="text__highlight">отчет о прогрессе</span> обучения для родителей' },
        { image: feature5, text: 'Поддержка <span class="text__highlight">опытных наставников</span> по школьной программе' },
        { image: feature6, text: 'Яркая, красочная и современная <span class="text__highlight">интерактивная среда </span>для обучения' },
        { image: feature7, text: 'Живые уроки с <span class="text__highlight">квалифицированными преподавателями</span>' },
        { image: feature8, text: 'Понятные <span class="text__highlight">наглядные объяснения</span> на увлекательных уроках' },
    ];

    return (
        <div className="main__section" id='about'>
            <div className="main__section__platform">
                <div className="main__section__platform__content">
                    <Swiper
                        className="main__section__platform__content__slider"
                        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                        grabCursor={true}
                        slidesPerView={1}
                        spaceBetween={10}
                        pagination={{ clickable: true }}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: true,
                        }}
                        loop={true}
                        loopedSlides={null}
                        breakpoints={{
                            769: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            426: {
                                slidesPerView: 2,
                                spaceBetween: 30,
                            },
                            0: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                        }}
                    >
                        {slides.map((slide, index) => (
                            <SwiperSlide key={index} className="main__section__platform__content__slider__item">
                                <div className="main__section__platform__content__slider__item__imgBox">
                                    <img src={slide.image} alt={`Feature ${index + 1}`} className="main__section__platform__content__slider__item__imgBox__img" />
                                </div>
                                <p className="main__section__platform__content__slider__item__text" dangerouslySetInnerHTML={{ __html: slide.text }}></p>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className="main__section__platform__content__info">
                        <img src={stars} alt="Stars" className="main__section__platform__content__info__icon" />
                        <p className="main__section__platform__content__info__text">
                            Проводим занятия онлайн в <span className="main__section__platform__content__info__text__bold">удобное время</span> 24/7
                        </p>
                        <p className="main__section__platform__content__info__text">
                            Подберём педагога с учётом <span className="main__section__platform__content__info__text__bold">психологических особенностей</span> и уровня знаний
                        </p>
                        <p className="main__section__platform__content__info__text">
                            Яркие и запоминающиеся занятия на <span className="main__section__platform__content__info__text__bold">интерактивной платформе</span>
                        </p>
                        <p className="main__section__platform__content__info__text">
                            Только эффективные методики и только <span className="main__section__platform__content__info__text__bold">индивидуальный подход</span>
                        </p>
                        <Button text="оставить заявку" isLong={true} onOpenModal={onOpenModal} /> {/* Передаем функцию для открытия модального окна */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Platform;
