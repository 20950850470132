import React from "react";
// import { useState, useRef } from "react";

import { TeacherInfo } from "./arrays/TeacherInfo";
// import { useParams } from "react-router-dom";

import HystModal from "hystmodal";
import '../../node_modules/hystmodal/dist/hystmodal.min.css'
// import '../../node_modules/hystmodal/dist/hystmodal.min.js'

function TeacherCard() {

    const myModal = new HystModal({
        linkAttributeName: "data-hystmodal",
        // настройки (не обязательно), см. API
    });


    return(
        <div>
            {TeacherInfo.map((data, key) => {
                return (
                    <div className="main__section__teachers__popUp hystmodal" id={data.modal} aria-hidden="true">
                        <div key={key} className="main__section__teachers__popUp__modal hystmodal__wrap">
                            <div className="main__section__teachers__popUp__modal__content hystmodal__window window__teacher" role="dialog" aria-modal="true">
                                <button data-hystclose className="hystmodal__close">Закрыть</button>
                                <div className="main__section__teachers__popUp__modal__content__left ">
                                    <img loading="lazy" src={data.img} alt="teacher photo" className="main__section__teachers__popUp__modal__content__left__img" />
                                    <div className="main__section__teachers__popUp__modal__content__left__info">
                                        <h3 className="main__section__teachers__popUp__modal__content__left__info__name">{data.name}</h3>
                                        <p className="main__section__teachers__popUp__modal__content__left__info__post">{data.post}</p>
                                    </div>
                                    
                                </div>
                                <div className="main__section__teachers__popUp__modal__content__right">
                                    {Object.values(data.info).map((info, index) => (
                                        <p className="main__section__teachers__popUp__modal__content__right__text" key={index}>
                                            {info}
                                        </p>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    )
}

export default TeacherCard;
