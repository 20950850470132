// Валидация с сообщением-обманкой для запрещенных слов


import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from 'axios';
// import { YMInitializer } from 'react-yandex-metrika';
// import ym from 'react-yandex-metrika';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';

function LeadForm() {
  const [isFormValid, setIsFormValid] = useState(false);
  const [isNameForbidden, setIsNameForbidden] = useState(false);

  const handleSubmit = async (values, { setSubmitting, setStatus }) => {
    try {
      const apiUrl = '/send_lead';
      const requestData = {
        phone: values.phone,
        name: values.name,
      };

      if (isNameForbidden) {
        // Если имя запрещено, устанавливаем обманное сообщение об успешной отправке
        setStatus({ success: true });
      } else {
        // Иначе отправляем запрос на сервер
        const response = await axios.post(apiUrl, requestData);
        if (response.data.success) {
          console.log("Lead sent", response.data);
          setStatus({ success: true });
        } else {
          setStatus({ success: false });
          console.error('Error sending lead', response.data.message);
        }
      }
    } catch (error) {
      console.error('Error sending lead', error);
      setStatus({ success: false });
    }
  };

  const forbiddenNames = [
    'пидор',
    'сука',
    'pidor',
    'suka',
    'жопа',
    'zhopa',
  ];

  const forbiddenPhoneNumbers = [
    '+7(111) 111-11-11',
    '+7(999) 999-99-99',
    '+7(123) 456-78-90',
    '+7(098) 765-43-21',
    '+7(222) 222-22-22',
    '+7(333) 333-33-33',
    '+7(444) 444-44-44',
    '+7(555) 555-55-55',
    '+7(666) 666-66-66',
    '+7(777) 777-77-77',
    '+7(888) 888-88-88',
  ];

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('*Заполните поле')
      .matches(/^[A-Za-zА-Яа-яЁё\s]+$/, '*Имя не может содержать цифры или символы')
      .test('forbidden-name', '*Введите корректное имя', (value) => {
        if (forbiddenNames.includes(value)) {
          setIsNameForbidden(true); // Устанавливаем, что имя запрещено
          return true; // Возвращаем true, чтобы считать валидацию успешной
        }
        setIsNameForbidden(false); // Устанавливаем, что имя не запрещено
        return true; // Возвращаем true, чтобы считать валидацию успешной
      }),
    phone: Yup.string()
      .required('*Заполните поле')
      .matches(
        /^\+7\(\d{3}\) \d{3}-\d{2}-\d{2}$/,
        '*Введите корректный российский номер телефона'
      )
      .notOneOf(forbiddenPhoneNumbers, '*Введите корректный российский номер телефона'),
  });

  return (
    <Formik
      initialValues={{ name: '', phone: '' }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validate={(values) => {
        const errors = {};
        if (!values.name) {
          errors.name = '*Заполните поле';
        }
        if (!values.phone || !/^\+7\(\d{3}\) \d{3}-\d{2}-\d{2}$/.test(values.phone)) {
          errors.phone = '*Введите корректный российский номер телефона';
        }
        setIsFormValid(Object.keys(errors).length === 0);
        return errors;
      }}
    >
      {({ isSubmitting, status, values, setFieldValue, isValid }) => (
        <Form className="main__section__form__layout">
          {/* <YMInitializer accounts={[94963600]} options={{ webvisor: true }} /> */}
          <h1 className="main__section__form__layout__title">Заявка на пробное занятие</h1>
          <p className="main__section__form__layout__text">
            Оставьте свой номер телефона, наш менеджер перезвонит и запишет Вас на пробное занятие в удобное время
          </p>
          <div className="main__section__form__layout__item">
            <Field 
              type="text" 
              id="name" 
              placeholder="Ваше имя" 
              name="name" 
              className="main__section__form__layout__item__input" 
            />
            <ErrorMessage name="name" component="div" className="main__section__form__layout__item__error" />
          </div>
          <div className="main__section__form__layout__item">
            <Field 
              id="phone" 
              name="phone"
              className="main__section__form__layout__item__input" 
            >
              {({ field }) => (
                <InputMask
                  {...field}
                  mask="+7(999) 999-99-99"
                  placeholder="Ваш номер телефона"
                  className="main__section__form__layout__item__input" 
                  beforeMaskedValueChange={(newState, oldState, userInput) => {
                    // Проверяем, что новое состояние не находится в списке запрещенных номеров
                    const newValue = newState.value;
                    if (forbiddenPhoneNumbers.includes(newValue)) {
                      return oldState; // Возвращаем старое состояние, чтобы отклонить ввод
                    }
                    return newState;
                  }}
                />
              )}
            </Field>
            <ErrorMessage name="phone" component="div" className="main__section__form__layout__item__error" />
          </div>
          <button 
            type="submit" 
            disabled={isSubmitting || !isFormValid} 
            className={`main__section__form__layout__button ${isSubmitting || !isFormValid ? 'disabled' : ''}`}
          >
            Отправить
          </button>
          {status && (status.success || status.fakeSuccess) ? (
            <div className="main__section__form__layout__success">
              <h3 className="main__section__form__layout__success__title">
                {status.fakeSuccess ? 'Ваш запрос обманут' : 'Спасибо за Вашу заявку'}
              </h3>
              <p className="main__section__form__layout__success__p">
                {status.fakeSuccess ? 'Мы получили ваш запрос, но не можем его принять' : 'Наш менеджер скоро свяжется с Вами!'}
              </p>
            </div>
          ) : null}
        </Form>
      )}
    </Formik>
  );
}

export default LeadForm;
