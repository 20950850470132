import React from "react";
// import { useState, useRef } from "react";
// import { useParams } from "react-router-dom";

import TeacherCard from "./teacherCard";
import { TeacherInfo } from "./arrays/TeacherInfo";

import AOS from 'aos';
import 'aos/dist/aos.css';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import 'swiper/css/effect-cards';

import arrowMore from '../img/arrowMore.svg'

function TeacherSlider () {
    AOS.init();
    return(
        <div className="main__section" id="teachers">
            <div className="main__section__teachers">
                <h1 className="main__section__teachers__title">Наши преподаватели</h1>
                <div className="main__section__teachers__container">
                    <Swiper
                        className='main__section__teachers__container__slider'
                        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                        grabCursor={true}
                        slidesPerView={3}
                        spaceBetween={0}
                        navigation
                        pagination={{ clickable: true }}
                        autoplay={{
                            delay: 3500,
                            disableOnInteraction: false,
                        }}
                        loop={false}
                        loopedSlides={null}
                        breakpoints={{
                            1240: {
                                slidesPerView: 3,
                            },
                            768: {
                                slidesPerView: 2,
                            },
                            0: {
                                slidesPerView: 1,
                                },
                            }}
                    >
                        {TeacherInfo.map((data, key) => {
                            return (
                                <SwiperSlide key={key} className='main__section__teachers__container__slider__slide'>
                                    <div className="main__section__teachers__container__slider__slide__card">
                                        <img loading="lazy" src={data.img} alt="teacher" className="main__section__teachers__container__slider__slide__card__img" />
                                        <div className="main__section__teachers__container__slider__slide__card__hover">
                                            <div className="main__section__teachers__container__slider__slide__card__hover__container">
                                                <h3 className="main__section__teachers__container__slider__slide__card__hover__container__name">{data.name}</h3>
                                                <p className="main__section__teachers__container__slider__slide__card__hover__container__info">
                                                    {data.info[0]}
                                                </p>
                                                <button data-hystmodal={"#" + data.modal} className="main__section__teachers__container__slider__slide__card__hover__container__button">
                                                    Подробнее
                                                    <img loading="lazy" src={arrowMore} alt="arrow" className="main__section__teachers__container__slider__slide__card__hover__container__button__arrow" />
                                                </button>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <h3 className="main__section__teachers__container__slider__slide__name">{data.name}</h3>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>
                <TeacherCard/>
            </div>
        </div>
    )
}


export default TeacherSlider;
