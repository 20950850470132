import './css/style.css'

import { YMInitializer } from 'react-yandex-metrika';

import Header from './components/Header'
import Main from './components/Main';
import Footer from './components/Footer';

function App() {
  return (
    <div className="app">
      <YMInitializer 
        accounts={[98807016]} 
        options={{
          clickmap: true,
          trackLinks: true,
          webvisor: true,
          trackHash: true,
        }} 
      />
      <Header/>
      <Main/>
      <Footer/>
    </div>
  );
}

export default App;
