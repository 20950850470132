// import english from '../../img/directions/english.png';
// import chineese from '../../img/directions/chineese.png';
import english from '../../img/directions/english2.webp';
import chineese from '../../img/directions/chineese2.webp';
import kids from '../../img/directions/kids.webp';
import school from '../../img/directions/primary-school.webp';
import oge from '../../img/directions/oge.webp';

export const DirectionsInfo = [
    {
        id: "english",
        title: 'Английский язык',
        img: english,
        link: 'https://my-english.club/',
        info: [
            'Давно мечтаете выучить английский? Или хотите дать хороший старт в виде знания языка своему ребенку? В нашей школе заговорят по-английски все. ', 
            'Используем самые современные методы и разработки: коммуникативную методику, <span class="text__bold">игровые механики</span>,интерактивные приложения, мультфильмы и видео. ',
            '<span class="text__bold">Решим проблемы с английским языком в школе</span>. Выявим и ликвидируем пробелы в знаниях. Ваш ребенок <span class="text__bold">опередит своих одноклассников в школе</span> и сможет делать уроки сам. Подготовим к <span class="text__bold">ОГЭ/ЕГЭ</span> на высокий балл.',
            'При подборе преподавателя и группы учитываем психологические особенности и создаем комфортные условия для обучения.',
            '<div class="cost"><div class="text__bold">Стоимость занятий:</div> <p class="cost__list">В группе - от 460 р за 45 мин.</p> <p class="cost__list">Индивидуально – от 860 р. за 45 мин.</p></div>',
            '<span class="text__bold">НЕ ЗНАЕТЕ, С ЧЕГО НАЧАТЬ?</span> Записывайтесь на пробный урок. Наш методист оценит ваш уровень знаний и подберет программу для обучения.',
        ],
    },
    {
        id: "chinese",
        title: 'Китайский язык',
        img: chineese,
        link: 'https://chinese.shineschool.ru/',
        info: [
            'Освойте один из самых перспективных языков в нашей школе.', 
            'Предлагаем курс для детей от 7 лет.',
            '<span class="text__bold">Наши маленькие ученики действительно говорят по-китайски.</span> Уже на первом занятии они выучивают несколько фраз, пишут свои первые иероглифы и осваивают основы тональной системы китайского языка.',
            '<div class="cost"><div class="text__bold">Для взрослых и старших подростков предлагаем курсы китайского</div> <p class="cost__list">&bull; для поступления в вуз в Китае</p> <p class="cost__list">&bull; для подготовки к экзамену HSK</p> <p class="cost__list">&bull; для путешествий</p> <p class="cost__list">&bull; для бизнеса</p></div>',
            '<div class="cost"><div class="text__bold">Стоимость занятий:</div> <p class="cost__list">В группе - от 490 р за 45 мин.</p> <p class="cost__list">Индивидуально – от 860 р. за 45 мин.</p></div>',
            '<span class="text__bold">Говорят, китайский очень сложный, но наши преподаватели доказывают, что то не так.</span>',
            '<span class="text__bold">СОМНЕВАЕТЕСЬ, ПОЛУЧИТСЯ ЛИ У ВАС?</span> Просто запишитесь на пробный урок, узнаете сами. ', 
        ],
    },
    {
        id: "kids",
        title: 'Подготовка к школе',
        img: kids,
        link: 'https://kids.shineschool.ru/',
        info: [
            'Подготовим дошколят к обучению в школе и научим читать по <span class="text__bold">эффективной методике</span>, используя <span class="text__bold">нейроподход</span>.',
            'В увлекательной форме поможем разобраться с основами математики и русского, а также расширить кругозор и развить логику.',
            'Подбираем преподавателя индивидуально, чтобы ребенку было комфортно.',
            'У нас на занятиях ошибаться не страшно, можно спокойно и с удовольствием познавать новое. Интерактивные материалы на платформе и игровые задания <span class="text__bold">повышают мотивацию</span> к учебе.',
            'В результате ребенок пойдет в школу с <span class="text__bold">уверенностью в своих силах</span>.',
            '<div class="cost"><div class="text__bold">Стоимость занятий:</div> <p class="cost__list">В группе - от 530 р за 60 мин.</p> <p class="cost__list">Индивидуально – от 860 р. за 45 мин.</p></div>',
            '<span class="text__bold">НЕ УВЕРЕНЫ, ПОДОЙДЕТ ЛИ ФОРМАТ?</span> Записывайтесь на пробный урок. Ваш ребенок полюбит учиться.',
        ],
    },
    {
        id: "school",
        title: 'Начальная школа',
        img: school,
        link: '',
        info: [
            'У ребенка возникли проблемы с предметами начальной школы? Родителям не хватает времени и сил, чтобы помочь? ',
            'Наши онлайн-занятия с <span class="text__bold">профессиональным педагогом</span> решат эту проблему.',
            'Подтягиваем недостающие навыки, используя <span class="text__bold">индивидуальный подход</span> и <span class="text__bold">нейроприемы</span>.',
            'Уроки делаем быстро и без слез. При этом ребенок сам понимает, как выполнять задание.',
            'Мы любим детей, поэтому мы ищем такой подход, чтобы ребенок поверил в себя и научился <span class="text__bold">работать сам</span>.',
            '<div class="cost"><div class="text__bold">Стоимость занятий:</div> <p class="cost__list">Индивидуально – от 900 р. за 60 мин.</p></div>',
            '<span class="text__bold">СОМНЕВАЕТЕСЬ, ПОМОГУТ ЛИ ЗАНЯТИЯ ОНЛАЙН?</span> Просто запишитесь на пробный урок и оцените результат. '
        ],
    },
    {
        id: "oge",
        title: 'Подготовка к ОГЭ',
        img: oge,
        link: 'https://oge.shineschool.ru/',
        info: [
            'В нашей школе на курсе ОГЭ Turbo English вы подготовитесь к ОГЭ на высокий балл.', 
            'Предлагаем курс в <span class="text__bold">групповом формате</span> для разных уровней подготовки.',
            'Сначала мы определим <span class="text__bold">исходный уровень</span> владения языком, слабые и сильные стороны, затем подберем оптимальную <span class="text__bold">траекторию подготовки</span> к экзамену.',
            'Детально проработаем структуру и эффективные стратегии выполнения всех разделов ОГЭ под руководством <span class="text__bold">опытного наставника</span> в формате живых уроков онлайн.',
            'Не просто даем памятки по лексике и грамматике для самостоятельного изучения, а <span class="text__bold">нарабатываем навыки</span> их применения в речи и письменных заданиях.',
            'Практикуем <span class="text__bold">экзаменационный формат</span> на уроках и в домашней работе на платформе. Используем <span class="text__bold">реальные</span> экзаменационные материалы.',
            'Ликвидируем пробелы в грамматике и словообразовании.',
            'Ваше письмо и говорение оценит <span class="text__bold">эксперт ОГЭ</span> (в пакете с проверкой).',
            'Следим за прогрессом, отвечаем на вопросы, даем <span class="text__bold">обратную связь</span>.',
            '<div class="cost"><div class="text__bold">Стоимость занятий:</div> <p class="cost__list">От 2000 р. до 15500 р за курс в зависимости от пакета (см. Подробнее).</p></div>',
        ],
    },
]