import React, { useState } from 'react';
import { QuestionsInfo } from './arrays/QuestionsInfo';

function Questions() {
    const [activeQuestion, setActiveQuestion] = useState(null);

    // Функция для переключения состояния вопроса (раскрытие/сворачивание)
    const toggleQuestion = (id) => {
        setActiveQuestion(activeQuestion === id ? null : id);
    };

    return (
        <div className="main__section" id='questions'>
            <div className="main__section__questions">
                <h2 className="main__section__advantages__title">Вопрос-ответ</h2>
                <div className="main__section__questions__container">
                    {QuestionsInfo.map(({ id, question, answer }) => (
                        <div 
                            key={id} 
                            className={`main__section__questions__container__item ${activeQuestion === id ? 'active' : ''}`} 
                            onClick={() => toggleQuestion(id)}
                        >
                            <div className="main__section__questions__container__item__header">
                                <span className="main__section__questions__container__item__question">{question}</span>
                                <span className="main__section__questions__container__item__icon">
                                    {activeQuestion === id ? '-' : '+'}
                                </span>
                            </div>
                            {activeQuestion === id && (
                                <div className="main__section__questions__container__item__answer">
                                    {answer.map((line, index) => (
                                        <p key={index} className="main__section__questions__container__item__answer__text">{line}</p>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                
            </div>
        </div>
    );
}

export default Questions;
