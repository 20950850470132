export const QuestionsInfo = [
    {
        id: 1,
        modal: 'question1',
        question: 'Что нужно для занятий онлайн?',
        answer: [
            'Ноутбук, компьютер или планшет (с телефона заниматься возможно, но не желательно). Встроенные или дополнительные устройства для видео и аудио передачи (камера, микрофон, динамики, наушники). Устойчивое Интернет-соединение, скорость которого позволяет смотреть видео. Мы рекомендуем использовать свежие версии популярных браузеров.',
        ]
    },
    {
        id: 2,
        modal: 'question2',
        question: 'Не получается прийти на урок. Что делать?',
        answer: [
            'Ничего страшного. Такие ситуации случаются.', 
            'Если Вы обучаетесь в группе, то мы предоставим Вам запись пропущенного занятия, все материалы к уроку и домашнее задание будут доступны на платформе.',
            'Если Вы обучаетесь индивидуально, то мы сможем перенести Ваш урок без потери оплаты, если Вы предупредите об отмене минимум за 3 часа до начала занятия. '
        ]
    },
    {
        id: 3,
        modal: 'question3',
        question: 'Для чего нужен пробный урок?',
        answer: [
            'Пробный урок нужен и Вам и нам. Его цель: определить уровень владения предметом (провести диагностику), поставить цели обучения, попробовать наш формат обучения. По результатам пробного урока мы сможем построить образовательную траекторию, порекомендовать подходящий формат обучения и подобрать педагога.',
        ]
    },
    {
        id: 4,
        modal: 'question4',
        question: 'А если мне не понравится педагог, которого мне дадут?',
        answer: [
            'Не стоит расстраиваться. Такое редко, но бывает. Мы поменяем Вам педагога, если Вы этого захотите. У нас найдется подходящий педагог для каждого',
        ]
    },
    {
        id: 5,
        modal: 'question5',
        question: 'Как построено обучение?',
        answer: [
            'Занятия проходят на нашей платформе в режиме реального времени. На экране Вы видите педагога и участников группы (для групповых занятий).  Вы можете общаться голосом, задавать вопросы в чате. На уроке используется интерактивная доска, задания в интерактивном режиме. Домашнее задание выдается на платформе в личном кабинете в виде заданий с автоматической проверкой или с проверкой педагогом. ',
        ]
    },
    {
        id: 6,
        modal: 'question6',
        question: 'Как я узнаю о прогрессе моего ребенка?',
        answer: [
            'Прогресс выполнения заданий сохраняется на платформе в личном кабинете, но это не дает полной картины. Поэтому 1 раз в месяц педагог дает полную обратную связь по работе каждого студента в письменной форме. Кроме того, в любое время через менеджера Вы можете задать вопросы или высказать пожелания по обучению. Вам обязательно ответит педагог или методист.',
        ]
    },
    {
        id: 7,
        modal: 'question7',
        question: 'Как я могу оплачивать занятия?',
        answer: [
            'Оплата занятий производится на нашем сайте. Выбираете пакет для оплаты, нажимаете кнопку «Оплатить», появляется интерфейс для ввода данных банковской карты. Оплата картой любого банка без комиссии.',
        ]
    },
    {
        id: 8,
        modal: 'question8',
        question: 'Нужно ли приобретать дополнительно какие-то пособия?',
        answer: [
            'Нет. Все, что необходимо для обучения, есть на нашей обучающей платформе.',
        ]
    },
];