import Diploma1 from '../../img/Diplomas/certificate-1.webp'
import Diploma2 from '../../img/Diplomas/certificate-2.webp'
import Diploma3 from '../../img/Diplomas/certificate-3.webp'
import Diploma4 from '../../img/Diplomas/certificate-4.webp'
import Diploma5 from '../../img/Diplomas/certificate-5.webp'
import Diploma6 from '../../img/Diplomas/certificate-6.webp'
import Diploma7 from '../../img/Diplomas/certificate-7.webp'
import Diploma8 from '../../img/Diplomas/certificate-8.webp'
import Diploma9 from '../../img/Diplomas/certificate-9.webp'
import Diploma10 from '../../img/Diplomas/certificate-10.webp'
import Diploma11 from '../../img/Diplomas/certificate-11.webp'
import Diploma12 from '../../img/Diplomas/certificate-12.webp'
import Diploma13 from '../../img/Diplomas/certificate-13.webp'
import Diploma14 from '../../img/Diplomas/certificate-14.webp'
import Diploma15 from '../../img/Diplomas/certificate-15.webp'
import Diploma16 from '../../img/Diplomas/certificate-16.webp'
import Diploma17 from '../../img/Diplomas/certificate-17.webp'
import Diploma18 from '../../img/Diplomas/certificate-18.webp'
import Diploma19 from '../../img/Diplomas/certificate-19.webp'
import Diploma20 from '../../img/Diplomas/certificate-20.webp'
import Diploma21 from '../../img/Diplomas/certificate-21.webp'
import Diploma22 from '../../img/Diplomas/certificate-22.webp'
import Diploma23 from '../../img/Diplomas/certificate-23.webp'
import Diploma24 from '../../img/Diplomas/certificate-24.webp'
import Diploma25 from '../../img/Diplomas/certificate-25.webp'
import Diploma26 from '../../img/Diplomas/certificate-26.webp'
import Diploma27 from '../../img/Diplomas/certificate-27.webp'
import Diploma28 from '../../img/Diplomas/certificate-28.webp'
import Diploma29 from '../../img/Diplomas/certificate-29.webp'
import Diploma30 from '../../img/Diplomas/certificate-30.webp'
import Diploma31 from '../../img/Diplomas/certificate-31.webp'
import Diploma32 from '../../img/Diplomas/certificate-32.webp'
import Diploma33 from '../../img/Diplomas/certificate-33.webp'
import Diploma34 from '../../img/Diplomas/certificate-34.webp'
import Diploma35 from '../../img/Diplomas/certificate-35.webp'
import Diploma36 from '../../img/Diplomas/certificate-36.webp'
import Diploma37 from '../../img/Diplomas/certificate-37.webp'
import Diploma38 from '../../img/Diplomas/certificate-38.webp'
import Diploma39 from '../../img/Diplomas/certificate-39.webp'
import Diploma40 from '../../img/Diplomas/certificate-40.webp'
import Diploma41 from '../../img/Diplomas/certificate-41.webp'
import Diploma42 from '../../img/Diplomas/certificate-42.webp'
import Diploma43 from '../../img/Diplomas/certificate-43.webp'
import Diploma44 from '../../img/Diplomas/certificate-44.webp'
import Diploma45 from '../../img/Diplomas/certificate-45.webp'
import Diploma46 from '../../img/Diplomas/certificate-46.webp'
import Diploma47 from '../../img/Diplomas/certificate-47.webp'
import Diploma48 from '../../img/Diplomas/certificate-48.webp'



export const Diplomas = [
    {
        id: 1,
        modal: 'Diploma1',
        img: Diploma1,
    },
    {
        id: 2,
        modal: 'Diploma2',
        img: Diploma2,
    },
    {
        id: 3,
        modal: 'Diploma3',
        img: Diploma3,
    },
    {
        id: 4,
        modal: 'Diploma4',
        img: Diploma4,
    },
    {
        id: 5,
        modal: 'Diploma5',
        img: Diploma5,
    },
    {
        id: 6,
        modal: 'Diploma6',
        img: Diploma6,
    },
    {
        id: 7,
        modal: 'Diploma7',
        img: Diploma7,
    },
    {
        id: 8,
        modal: 'Diploma8',
        img: Diploma8,
    },
    {
        id: 9,
        modal: 'Diploma9',
        img: Diploma9,
    },
    {
        id: 10,
        modal: 'Diploma10',
        img: Diploma10,
    },
    {
        id: 11,
        modal: 'Diploma11',
        img: Diploma11,
    },
    {
        id: 12,
        modal: 'Diploma12',
        img: Diploma12,
    },
    {
        id: 13,
        modal: 'Diploma13',
        img: Diploma13,
    },
    {
        id: 14,
        modal: 'Diploma14',
        img: Diploma14,
    },
    {
        id: 15,
        modal: 'Diploma15',
        img: Diploma15,
    },
    {
        id: 16,
        modal: 'Diploma16',
        img: Diploma16,
    },
    {
        id: 17,
        modal: 'Diploma17',
        img: Diploma17,
    },
    {
        id: 18,
        modal: 'Diploma18',
        img: Diploma18,
    },
    {
        id: 19,
        modal: 'Diploma19',
        img: Diploma19,
    },
    {
        id: 20,
        modal: 'Diploma20',
        img: Diploma20,
    },
    {
        id: 21,
        modal: 'Diploma21',
        img: Diploma21,
    },
    {
        id: 22,
        modal: 'Diploma22',
        img: Diploma22,
    },
    {
        id: 23,
        modal: 'Diploma23',
        img: Diploma23,
    },
    {
        id: 24,
        modal: 'Diploma24',
        img: Diploma24,
    },
    {
        id: 25,
        modal: 'Diploma25',
        img: Diploma25,
    },
    {
        id: 26,
        modal: 'Diploma26',
        img: Diploma26,
    },
    {
        id: 27,
        modal: 'Diploma27',
        img: Diploma27,
    },
    {
        id: 28,
        modal: 'Diploma28',
        img: Diploma28,
    },
    {
        id: 29,
        modal: 'Diploma29',
        img: Diploma29,
    },
    {
        id: 30,
        modal: 'Diploma30',
        img: Diploma30,
    },
    {
        id: 31,
        modal: 'Diploma31',
        img: Diploma31,
    },
    {
        id: 32,
        modal: 'Diploma32',
        img: Diploma32,
    },
    {
        id: 33,
        modal: 'Diploma33',
        img: Diploma33,
    },
    {
        id: 34,
        modal: 'Diploma34',
        img: Diploma34,
    },
    {
        id: 35,
        modal: 'Diploma35',
        img: Diploma35,
    },
    {
        id: 36,
        modal: 'Diploma36',
        img: Diploma36,
    },
    {
        id: 37,
        modal: 'Diploma37',
        img: Diploma37,
    },
    {
        id: 38,
        modal: 'Diploma38',
        img: Diploma38,
    },
    {
        id: 39,
        modal: 'Diploma39',
        img: Diploma39,
    },
    {
        id: 40,
        modal: 'Diploma40',
        img: Diploma40,
    },
    {
        id: 41,
        modal: 'Diploma41',
        img: Diploma41,
    },
    {
        id: 42,
        modal: 'Diploma42',
        img: Diploma42,
    },
    {
        id: 43,
        modal: 'Diploma43',
        img: Diploma43,
    },
    {
        id: 44,
        modal: 'Diploma44',
        img: Diploma44,
    },
    {
        id: 45,
        modal: 'Diploma45',
        img: Diploma45,
    },
    {
        id: 46,
        modal: 'Diploma46',
        img: Diploma46,
    },
    {
        id: 47,
        modal: 'Diploma47',
        img: Diploma47,
    },
    {
        id: 48,
        modal: 'Diploma48',
        img: Diploma48,
    },
];