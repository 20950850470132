// import React, { useState, useEffect } from 'react';

// import Button from './Button';

// // import homeImg1 from '../img/home/homeImgLanguages.png';
// import homeImg1 from '../img/home/homeImgLanguages2.webp';
// import homeImg2 from '../img/home/homeImgKids.webp';
// import homeImg3 from '../img/home/homeImgExams.webp';

// const slides = [
//     {
//         image: homeImg1,
//         text: 'изучайте английский и китайский языки',
//     },
//     {
//         image: homeImg2,
//         text: 'помогите ребенку учиться легко в начальной школе',
//     },
//     {
//         image: homeImg3,
//         text: 'готовьтесь к экзаменам',
//     },
// ];

// function Home() {
//     const [currentSlide, setCurrentSlide] = useState(0);
//     const [fadeIn, setFadeIn] = useState(true);

//     useEffect(() => {
//         const interval = setInterval(() => {
//             setFadeIn(false); // Убираем текущий слайд (fade-out)
//             setTimeout(() => {
//                 setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
//                 setFadeIn(true); // Включаем новый слайд (fade-in)
//             }, 500); // Время для завершения fade-out перед сменой слайда
//         }, 3000); // Интервал между слайдами

//         return () => clearInterval(interval);
//     }, []);

//     return (
//         <div className="main__section__home">
//             <div className="main__section__home__content">
//                 <div className="main__section__home__content__info">
//                     <div className="main__section__home__content__info__text">
//                         <div className="main__section__home__content__info__text__title">
//                             <h1 className="main__section__home__content__info__text__title__h1">
//                                 Shine School
//                             </h1>
//                             <p className="main__section__home__content__info__text__title__slogan">
//                                 Обучайтесь онлайн с лучшими преподавателями
//                             </p>
//                         </div>
//                         <ul className={`main__section__home__content__info__text__list ${fadeIn ? 'fade-in' : ''}`}>
//                             {slides.map((slide, index) => (
//                                 <li
//                                     key={index}
//                                     className={index === currentSlide ? 'highlight' : ''}
//                                     dangerouslySetInnerHTML={{ __html: slide.text }}
//                                 />
//                             ))}
//                         </ul>
//                     </div>
//                     <div className="main__section__home__content__info__button">
//                         <Button text="подробнее" anchor="directions"/>
//                     </div>
//                     {/* <div className="main__section__home__content__info__buttonContainer">
//                         <button className="main__section__home__content__info__buttonContainer__button">
//                             <a href='#directions'  className="main__section__home__content__info__buttonContainer__button__a">
//                                 подробнее
//                             </a>
//                         </button>
//                     </div> */}
//                 </div>
//                 <div className="main__section__home__content__imageContainer">
//                     <img
//                         src={slides[currentSlide].image}
//                         alt={`Slide ${currentSlide + 1}`}
//                         className={`main__section__home__content__imageContainer__img ${currentSlide === 2 ? 'homeImg3-class' : ''}`}
//                     />
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default Home;










import React, { useState, useEffect } from 'react';
import Button from './Button';

// Импорт изображений
import homeImg1 from '../img/home/homeImgLanguages2.webp';
import homeImg2 from '../img/home/homeImgKids.webp';
import homeImg3 from '../img/home/homeImgExams.webp';

// Массив слайдов
const slides = [
    {
        image: homeImg1,
        text: 'Изучайте английский и китайский языки',
    },
    {
        image: homeImg2,
        text: 'Помогите ребенку учиться легко в начальной школе',
    },
    {
        image: homeImg3,
        text: 'Готовьтесь к экзаменам',
        customClass: 'homeImg3-class' // Добавляем уникальный класс для этого слайда
    },
];

function Home() {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [fadeIn, setFadeIn] = useState(true);

    // Прелоадинг изображений
    useEffect(() => {
        slides.forEach(slide => {
            const img = new Image();
            img.src = slide.image;
        });
    }, []);

    // Логика смены слайдов
    useEffect(() => {
        const interval = setInterval(() => {
            setFadeIn(false);
            setTimeout(() => {
                setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
                setFadeIn(true);
            }, 500); // Время fade-out перед сменой слайда
        }, 3000); // Интервал смены слайдов

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="main__section__home">
            <div className="main__section__home__content">
                <div className="main__section__home__content__info">
                    <div className="main__section__home__content__info__text">
                        <div className="main__section__home__content__info__text__title">
                            <h1 className="main__section__home__content__info__text__title__h1">
                                Shine School
                            </h1>
                            <p className="main__section__home__content__info__text__title__slogan">
                                Обучайтесь онлайн с лучшими преподавателями
                            </p>
                        </div>
                        <ul className={`main__section__home__content__info__text__list ${fadeIn ? 'fade-in' : ''}`}>
                            <li className={currentSlide === 0 ? 'highlight' : ''}>
                                {slides[0].text}
                            </li>
                            <li className={currentSlide === 1 ? 'highlight' : ''}>
                                {slides[1].text}
                            </li>
                            <li className={currentSlide === 2 ? 'highlight' : ''}>
                                {slides[2].text}
                            </li>
                        </ul>
                    </div>
                    <div className="main__section__home__content__info__button">
                        <Button text="подробнее" anchor="directions" />
                    </div>
                </div>
                <div className="main__section__home__content__imageContainer">
                    {slides.map((slide, index) => (
                        <img
                            key={index}
                            src={slide.image}
                            alt={`Slide ${index + 1}`}
                            className={`main__section__home__content__imageContainer__img ${index === currentSlide ? 'visible' : 'hidden'} ${slide.customClass ? slide.customClass : ''}`}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Home;
