import React, { useState } from "react";

import HystModal from "hystmodal";
import '../../node_modules/hystmodal/dist/hystmodal.min.css'

//components
import Home from "./Home";
import Platform from "./platform";
import Directions from "./Directions";
import Advantages from "./Agvantages";
import TeacherSlider from "./TeachersSlider";
import DiplomaSlider from "./DiplomasSlider";
import Comments from "./VkComments";
import Questions from "./Questions";

//forms
import LeadForm from "./form";
import Programm from "./docs/Programm";

import up from '../img/up.svg'
import cross from '../img/cross.svg'




function Main() {
    const myModal = new HystModal({
        linkAttributeName: "data-hystmodal",
        closeOnEsc: true
    });

    const handleUpClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Добавляем плавное скроллирование
        });
    }

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        console.log('Opening modal'); 
        setIsModalOpen(true);
        document.body.style.overflow = 'hidden'; // Отключаем скроллинг
    };

    const closeModal = () => {
        setIsModalOpen(false);
        document.body.style.overflow = 'auto'; // Включаем скроллинг обратно
    };

    return (
        <main className="main">
            <img src={up} onClick={handleUpClick} alt="logo" id="pointer" className="main__up" />
            {/* <div className="main__section__form hystmodal" id='form' aria-hidden="true">
                <div className="hystmodal__wrap">
                    <div className="hystmodal__window" role="dialog" aria-modal="true">
                        <button data-hystclose className="hystmodal__close">Закрыть</button>
                        <LeadForm/>
                    </div>
                </div>
            </div> */}
            {isModalOpen && (
                <div className="modal" onClick={closeModal}> {/* Обработчик клика на фоне */}
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}> {/* Остановить событие при клике внутри контента */}
                        <LeadForm /> {/* Здесь отображается ваша форма */}
                        <button onClick={closeModal} className="modal-close">
                            <img src={cross} alt="Закрыть" />
                        </button>
                    </div>
                </div>
            )}
            <div className="main__section__form hystmodal" id='programm' aria-hidden="true">
                <div className="hystmodal__wrap">
                    <div className="hystmodal__window" role="dialog" aria-modal="true">
                        <button data-hystclose className="hystmodal__close">Закрыть</button>
                        <Programm/>
                    </div>
                </div>
            </div>
            <Home/>
            <Platform onOpenModal={openModal} />
            <Directions onOpenModal={openModal} />
            <Advantages/>
            <TeacherSlider/>
            <DiplomaSlider/>
            <Comments/>
            <Questions/>
        </main>
    )
}


export default Main;