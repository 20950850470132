import React from "react";
import { useState } from "react";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectCards } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import 'swiper/css/effect-cards';

import adv1 from '../img/advantages/advantage1.webp'
import adv2 from '../img/advantages/advantage2.webp'
import adv3 from '../img/advantages/advantage3.webp'
import adv4 from '../img/advantages/advantage4.webp'
import adv5 from '../img/advantages/advantage5.webp'
import adv6 from '../img/advantages/advantage6.webp'
import adv7 from '../img/advantages/advantage7.webp'
import adv8 from '../img/advantages/advantage8.webp'
import adv9 from '../img/advantages/advantage9.webp'
import adv10 from '../img/advantages/advantage10.webp'
import adv11 from '../img/advantages/advantage11.webp'

function Advantages() {
    return (
        <div className="main__section__advantages">
            <h2 className="main__section__advantages__title">Преимущества</h2>
            <div className="main__section__advantages__container">
                <Swiper
                    className='main__section__advantages__container__slider'
                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                    grabCursor={true}
                    slidesPerView={3}
                    spaceBetween={10}
                    navigation
                    pagination={{ clickable: true }}
                    autoplay={{
                        delay: 3500,
                        disableOnInteraction: false,
                    }}
                    loop={true}
                    loopedSlides={null}
                    breakpoints={{
                        1300: {
                            slidesPerView: 3,
                            spaceBetween: 10,
                        },
                        900: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        0: {
                            slidesPerView: 1,
                            spaceBetween: 10,
                        },
                    }}
                >
                    <SwiperSlide className='main__section__advantages__container__slider__slide'>
                        <img src={adv1} alt='advantages' className='main__section__advantages__container__slider__slide__img' />
                        <div className='main__section__advantages__container__slider__slide__name'>
                            500+ довольных учеников
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='main__section__advantages__container__slider__slide'>
                        <img src={adv2} alt='advantages' className='main__section__advantages__container__slider__slide__img' />
                        <div className='main__section__advantages__container__slider__slide__name'>
                            Возможность выбора подходящего темпа обучения
                        </div>
                    </SwiperSlide> 
                    <SwiperSlide className='main__section__advantages__container__slider__slide'>
                        <img src={adv3} alt='advantages' className='main__section__advantages__container__slider__slide__img' />
                        <div className='main__section__advantages__container__slider__slide__name'>
                            Бесплатное пробное занятие
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='main__section__advantages__container__slider__slide'>
                        <img src={adv4} alt='advantages' className='main__section__advantages__container__slider__slide__img' />
                        <div className='main__section__advantages__container__slider__slide__name'>
                            Возможность разработать индивидуальный план развития и программу для ученика
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='main__section__advantages__container__slider__slide'>
                        <img src={adv5} alt='advantages' className='main__section__advantages__container__slider__slide__img' />
                        <div className='main__section__advantages__container__slider__slide__name'>
                            Ежемесячная подробная обратная связь + прогресс на платформе
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='main__section__advantages__container__slider__slide'>
                        <img src={adv6} alt='advantages' className='main__section__advantages__container__slider__slide__img' />
                        <div className='main__section__advantages__container__slider__slide__name'>
                            Выгодная цена
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='main__section__advantages__container__slider__slide'>
                        <img src={adv7} alt='advantages' className='main__section__advantages__container__slider__slide__img' />
                        <div className='main__section__advantages__container__slider__slide__name'>
                            Квалифицированные преподаватели, умеющие работать онлайн
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='main__section__advantages__container__slider__slide'>
                        <img src={adv8} alt='advantages' className='main__section__advantages__container__slider__slide__img' />
                        <div className='main__section__advantages__container__slider__slide__name'>
                            Использование интерактивных материалов делает занятия интересными и привлекательными для ребёнка
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='main__section__advantages__container__slider__slide'>
                        <img src={adv9} alt='advantages' className='main__section__advantages__container__slider__slide__img' />
                        <div className='main__section__advantages__container__slider__slide__name'>
                            Обучение через игру увеличивает заинтересованность ребёнка и мотивирует его к дальнейшим занятиям
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='main__section__advantages__container__slider__slide'>
                        <img src={adv10} alt='advantages' className='main__section__advantages__container__slider__slide__img' />
                        <div className='main__section__advantages__container__slider__slide__name'>
                            Более 3 лех работы
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='main__section__advantages__container__slider__slide'>
                        <img src={adv11} alt='advantages' className='main__section__advantages__container__slider__slide__img' />
                        <div className='main__section__advantages__container__slider__slide__name'>
                            Индивидуальный подход. Занятия проходят в удобное для Вас время в мини-группах или индивидуально с педагогом
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    )
};

export default Advantages;




