import React from 'react';

import workProgramm from './work-programm-ecp.pdf'


function Programm() {
    return (
        <div className='main__section__form'>
            <div className='main__section__form__layout'>
                <h1 className="main__section__form__layout__title">Сведения об образовательной организации</h1>
                <p className="main__section__form__layout__textProgramm">
                    Программа
                </p>
                <a href={workProgramm} target="_blank" rel="noopener noreferrer" id="pointer" className="main__section__form__layout__textProgramm__a">
                    Дополнительная общеобразовательная общеразвивающая программа «Чтение на английском — 1 ступень»
                </a>
            </div>
        </div>
    )
}

export default Programm;